import React from 'react'
import { CardComponent } from '../card/card-component'
import CardPanel from '../card/panel'
import { BoxDetail, BoxWrapper, TextNoData } from '../box'

const style1 = { marginTop: '12%', width: '100%' }

export default props => (
  <BoxWrapper>
    <BoxDetail>
      <CardPanel title={props.locale.PUBLIC} locale={props.locale}>
        {props.data.map((v, i) => (
          <CardComponent
            className="is-card-slider"
            key={`card-${i}`}
            data={v}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        ))}
        {props.data.length <= 0 && (
          <div style={style1}>
            <TextNoData>{props.locale.NO_EVENT}</TextNoData>
          </div>
        )}
      </CardPanel>
    </BoxDetail>
  </BoxWrapper>
)
